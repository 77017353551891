// extracted by mini-css-extract-plugin
export var aws = "index-module--aws--4uwHp";
export var sdBanner = "index-module--sd-banner--On-ta";
export var sdFont14 = "index-module--sd-font-14--fd5cM";
export var sdFont16 = "index-module--sd-font-16--qbSI1";
export var sdFont18 = "index-module--sd-font-18--MeYOw";
export var sdFont20 = "index-module--sd-font-20--p3Pmy";
export var sdFont24 = "index-module--sd-font-24--WGzOl";
export var sdFont28 = "index-module--sd-font-28--1DkFT";
export var sdFont32 = "index-module--sd-font-32--LWtCK";
export var sdFont40 = "index-module--sd-font-40--21Uol";
export var sdH1 = "index-module--sd-h1--KjFrg";
export var sdH2 = "index-module--sd-h2--5td0Y";
export var sdH3 = "index-module--sd-h3--DwYRY";
export var sdH4 = "index-module--sd-h4--6pneB";
export var sdH5 = "index-module--sd-h5--sMxUC";
export var sdP = "index-module--sd-p--dFPyx";