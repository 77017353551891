import * as React from "react";
import classnames from "classnames";
import * as styles from "./index.module.less";

interface PropsType {
  className?: string;
  title: string;
  descs: Array<string> | string;
  showDisc?: boolean; // 是否显示列表前的小点点
}
const AwsSubContent = ({ className, title, descs, showDisc }: PropsType) => {
  const list = typeof descs === "string" ? [descs] : descs;

  return (
    <div className={classnames(styles.content, className)}>
      <h3 className="sd-font-20">{title}</h3>
      <ul className={classnames({ showDisc })}>
        {list?.map((el) => {
          return <li className="sd-font-14">{el}</li>;
        })}
      </ul>
    </div>
  );
};

export default AwsSubContent;
