import * as React from "react";
import SDLayout from "layouts/zh";
import SEO from "components/seo";
import AwsBanner from "components/Aws/Banner";
import SectionContent from "components/SectionContent";
import AwsSectionTitle from "components/Aws/SectionTitle";
import AwsSubContent from "components/Aws/SubContent";
import bottomImg from "assets/images/terms/aws/aws-img-bottom.png";
import WecomTemp from "assets/images/terms/aws/wecom-temp.png";
import * as styles from "./index.module.less";

const Aws = () => {
  const bannerDesc = [
    "2021 年度 APN 最佳技术合作伙伴奖；",
    "私有化部署版本和 SaaS 版本上线亚马逊云科技 Marketplace 中国；",
    "目前合作客户行业涵盖：保险，零售，跨境电商，企业服务，交易所等领域。",
  ];

  const contentOne = [
    "神策采用四位版本号体系，第一、二、三位版本号为主版本号，a 技术成熟、服务稳定 & 按需扩展、成本可控；",
    "丰富的存储类型，满足各种数据访问的需求：读优化，写优化，历史数据都可以根据需要配置不同的 IOPS 或者吞吐量，为应用提供最佳性价比的存储解决方案。",
    "AWS 独有的远程运维管理工具 SSM 让神策在满足客户安全合规的前提下提供运维支持。",
    "神策系统支持将历史数据归档到 S3，便于经济高效的保存和使用历史数据。",
    "AWS VPC 和安全组等安全组件可以在云上完全保护神策系统的安全性，避免受到攻击。如「神策分析 2.2.0」、「营销云 4.0.3」；该版本号说明，适用于分析云产品线的所有产品、营销云 4.0.3 以上版本产品。第四位版本号，为 build 号，仅供神策内部产研团队用于内部识别使用。",
  ];
  const contentTwo = [
    "Amazon EC2 - 弹性，安全，高可用的云服务器",
    "Amazon EBS – 多种卷类型，在最佳价格和性能之间取得平衡",
    "Amazon S3 - 简单持久、可大规模扩展的对象存储",
    "AWS VPC -  客户云中完全掌控的虚拟联网环境",
  ];
  const contentThree = [
    "某保险公司依托于亚马逊云进行业务运营、拓展，伴随着业务发展而来的是庞大的数据分析需求。因此，该公司经过调研，通过亚马逊云科技 Marketplace 查找并最终选择了神策数据产品，实现云上数据分析的需求。",
    "经过前期的需求调研，神策数据为该公司提供了全套云上数据分析解决方案，具体如下：",
  ];

  const contentFour = [
    {
      title: "1. 快速创建数据湖",
      descs: [
        "根据业务需求，将相关数据汇入数据湖",
        "神策对外提供多种数据接口和格式",
        "JDBC Driver，实时订阅 kafka，API 接口获取数据，归档到 S3",
      ],
    },
    {
      title: "2. 利用 AWS 安全架构简化云上数据安全",
      descs: ["执行加密", "定义访问策略", "实现审计登录"],
    },
    {
      title: "3. 创造云上的各种数据应用场景",
      descs: [
        "分析人员从单个数据目录中发现所有可用的数据",
        "对相同的数据使用多种分析工具",
      ],
    },
  ];

  const contentFive = [
    {
      title: "1. 神策的平台为业务运营团队提供便利",
      descs: [
        "运营团队不仅可以实时分析行为，还可以将用户行为与事务数据结合起来。"
      ],
    },
    {
      title: "2. 神策的平台减少数据团队的人力",
      descs: ["真正减少海量数据 ETL 处理繁杂的工作量，让研发团队专注于深度创新，提高整体工作效率。"],
    },
    {
      title: "3. 神策平台基于 AWS 提供更安全稳定的基础架构服务",
      descs: [
        "基于 AWS 账号的私有化部署的方式可以保证数据资产的安全和永久保存",
        "AWS 完善的数据处理功能和服务",
        "AWS 云服务的安全性、稳定性、灵活的计费方式"
      ],
    },
  ];

  return (
    <SDLayout>
      <SEO
        title="亚马逊云科技｜神策数据-合作伙伴"
        description="神策数据作为亚马逊云认证“技术合作伙伴”，依托亚马逊云提供的云存储支持和保障，为客户提供云上数据分析解决方案，帮助客户打通数据湖， 利用AWS技术保障云上数据安全，创造云上的各种数据应用场景，实现云上数据分析需求。"
        keywords="云存储,AWS认证,数据分析,数据安全,神策数据解决方案"
        saTitle="首页-关于我们（合作伙伴计划）-AWS"
      />
      <main className={styles.aws}>
        <AwsBanner
          className="banner"
          title="神策数据——亚马逊云认证“技术合作伙伴”"
          descs={bannerDesc}
        />
        {/* 亚马逊云科技为神策数据提供强大的云存储支持和安全保障 */}
        <SectionContent className="section">
          <AwsSectionTitle title="亚马逊云科技为神策数据提供强大的云存储支持和安全保障" />
          <AwsSubContent
            title="亚马逊云科技作为全球领先的云计算服务平台，为神策数据提供了强大的技术保障。"
            descs={contentOne}
          ></AwsSubContent>
          <AwsSubContent
            title="亚马逊云科技为神策数据提供了多种云存储方案"
            descs={contentTwo}
            showDisc
          ></AwsSubContent>
        </SectionContent>
        {/* 神策数据入驻亚马逊云，为客户提供云上数据分析解决方案 */}
        <SectionContent className="section">
          <AwsSectionTitle title="神策数据入驻亚马逊云，为客户提供云上数据分析解决方案" />
          <AwsSubContent
            title="某保险公司：打通亚马逊云数据湖，创造云上的各种数据应用场景"
            descs={contentThree}
          ></AwsSubContent>
          {contentFour?.map((item) => {
            return (
              <AwsSubContent
                className="listContent"
                title={item.title}
                descs={item.descs}
                showDisc
              />
            );
          })}
          <div className="bottom-img">
            <img
              src={bottomImg}
              alt="EMR 可以实现企业级安全性的大数据分析和机器学习数据处理"
              width="908px"
            />
          </div>
          <AwsSubContent
            title="某交易所：基于亚马逊云科技，完成对用户行为的深度探索"
            descs={[
              '公司依托于亚马逊云，基于神策分析、智能运营等服务完成交易所中客户运营的众多环节，有效完成资金沉淀，提高交易量。'
            ]}
          ></AwsSubContent>
          {contentFive?.map((item) => {
            return (
              <AwsSubContent
                className="listContent"
                title={item.title}
                descs={item.descs}
                showDisc
              />
            );
          })}
          <div className="bottom-img">
            <img
              src={WecomTemp}
              alt="某交易所：基于亚马逊云科技，完成对用户行为的深度探索"
              width="398px"
              style={{width: 398}}
            />
          </div>
        </SectionContent>
      </main>
    </SDLayout>
  );
};

export default Aws;
