import React from "react";
import classnames from "classnames";
import SectionContent from "components/SectionContent";
import * as styles from "./index.module.less";
interface Props {
  className?: string;
  title: string;
  descs: Array<string> | string;
}
const AwsBanner = ({ className, title, descs }: Props) => {
  const getDescTpl = () => {
    if (typeof descs === "string") {
      return <li className="sd-font-16">{descs}</li>;
    }
    return descs?.map((desc) => {
      return <li className="sd-font-16">{desc}</li>;
    });
  };

  return (
    <SectionContent className={classnames(styles.banner, className)}>
      <h1 className="sd-font-40">{title}</h1>
      <ul>{getDescTpl()}</ul>
    </SectionContent>
  );
};

export default AwsBanner;
