import * as React from "react";
import classnames from "classnames";
import * as styles from "./index.module.less";

interface PropsType {
  className?: string;
  title: string;
}

const AwsSectionTitle = ({ className, title }: PropsType) => {
  return (
    <div className={classnames(styles.title, className)}>
      <h2 className="sd-font-28">{title}</h2>
    </div>
  );
};

export default AwsSectionTitle;
